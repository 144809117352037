<template>
  <div>  
    <div class="page login">
    	<div class="container-small">
        <div class="text-center mb-5">
          <img alt="Cactus Plant Studio" src="../../assets/fav.png" class="cactus">
        </div>
        <h2 style="text-align:center;">Admin Login</h2>
        <div class="login__form">
          <form @submit.prevent>
            <div>
              <label for="email1">Email</label>
              <input type="text" placeholder="you@email.com" v-model.trim="loginForm.email" id="email1" />
            </div>
            <div>
              <label for="password1">Password</label>
              <input type="password" placeholder="******" id="password1" v-model.trim="loginForm.password" />
            </div>
            <button class="btn btn__primary mt-5 mb-5" @click="login()">
              Log In
              <transition name="fade">
                <span class="ml-2" v-if="performingRequest">
                <i class="fa fa-spinner fa-spin"></i>
                </span>
              </transition>
            </button>
          </form>
        </div>
      </div>
    </div>
    <Footer :user="currentUser" />
  </div>
</template>

<script>
import { mapState } from 'vuex'
const fb = require('../../firebaseConfig.js')
import Footer from '@/components/Footer.vue'

export default {
  name: 'login',
  data() {
    return {
      performingRequest: false,
      loginForm: {
        email: '',
        password: ''
      }
    }
  },
  components: {
    Footer
  },
  computed: {
    ...mapState(['currentUser']),
  },
  methods: {
    login() {
      this.performingRequest = true
      this.errorMsg = ''
      setTimeout(() => {
        fb.auth.signInWithEmailAndPassword(this.loginForm.email, this.loginForm.password).then(user => {
          console.log(user)
          this.$store.commit('setCurrentUser', user.user)
          this.$router.push('/dashboard')
        }).catch(err => {
          console.log(err)
          this.errorMsg = err.message
        })
        this.performingRequest = false
      }, 1000)
    },
  },
  metaInfo: {
    title: 'Admin Login',
  },
}
</script>